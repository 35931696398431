import PropTypes from "prop-types"
import React from "react"
import LinkStyled from "./styled/LinkStyled"

const MenuItem = props => (
  <LinkStyled to={props.to} {...props}>
    {props.children}
  </LinkStyled>
)

MenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default MenuItem
