import "lazysizes"
import "lazysizes/plugins/attrchange/ls.attrchange"

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import "typeface-montserrat"
import "typeface-lato"

import CookieConsent from "react-cookie-consent"
import GlobalStyle from "./styled/GlobalStyle"
import { ThemeProvider } from "styled-components"
import { Flex } from "rebass"

import theme from "../../theme"
import Container from "../Container"
import Header from "../Header"
import HeaderAddress from "../HeaderAddress"
import Logo from "../Logo"
import Main from "../Main"
import Footer from "../Footer"
import Menu from "../Menu"
import MenuItem from "../MenuItem"
import NoWrap from "../NoWrap"

import logoGM from "../../img/logoGM.svg"

// loads all images directly after page is loaded
// lazySizes.cfg.preloadAfterLoad = true

let browser = {}
// Wrap the require in check for window
if (typeof window !== `undefined`) {
  const bowser = require("bowser")
  browser = bowser.getParser(window.navigator.userAgent).getBrowser()
} else {
  browser.name = "undefined"
  browser.version = ""
}

const Layout = ({ children, props }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            date
          }
        }
      }
    `
  )
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <GlobalStyle />
        <Header>
          <HeaderAddress>
            Frankfurt/Main · 069–599761 ·&nbsp;
            <NoWrap>kontakt@hebamme-in-frankfurt.de</NoWrap>
          </HeaderAddress>
          <Logo
            as="div"
            to="/"
            img={logoGM}
            text1="Geesche Martin · Hebamme · "
            text2="Psychosoziale Beraterin M.A."
          />
          <Menu>
            <MenuItem to="/" activeClassName="active">
              Home
            </MenuItem>
            <MenuItem to="/psychosoziale-beratung" activeClassName="active">
              Psychosoziale Beratung
            </MenuItem>
            <MenuItem to="/schwangerschaft" activeClassName="active">
              Schwangerschaft
            </MenuItem>
            <MenuItem to="/wochenbett" activeClassName="active">
              Wochenbett
            </MenuItem>
            <MenuItem to="/akupunktur" activeClassName="active">
              Akupunktur
            </MenuItem>
            <MenuItem to="/vita" activeClassName="active">
              Vita
            </MenuItem>
            <MenuItem to="/kontakt" activeClassName="active">
              Kontakt
            </MenuItem>
          </Menu>
        </Header>
        <Main>{children}</Main>
        <Footer
          revision={site.siteMetadata.date}
          browser={browser.name + " " + browser.version}
        >
          <div>
            <Link to="/">© Geesche Martin</Link>

            <br />
            <a
              href="http://webseiten-anders.de"
              target="_blank"
              rel="nofollow"
              rel="noopener"
            >
              © webseiten anders
            </a>
          </div>
          <div>
            <Link to="/impressum/">Impressum</Link>
            <br />
            <Link to="/datenschutz/">Datenschutz</Link>
          </div>

          {/* <span>Revision: {site.siteMetadata.date}</span>
          <span>
            Browser: {}
          </span> */}
        </Footer>
        <CookieConsent
          location="bottom"
          buttonText="I stimme zu"
          cookieName="psbgm"
          style={{ background: "#465A65" }}
          buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
          expires={150}
        >
          Mit Nutzung dieser Webseite stimme ich der Verwendung von Cookies zu.
        </CookieConsent>
      </Container>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
